import I18n from 'i18n-js';
import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

const findDeviceUsedInStream = (devices, stream) => {
  if (devices.length === 0) {
    return { deviceId: 'default' };
  }

  return (
    devices.find((device) => {
      if (!stream) return false;
      return Boolean(
        stream.getTracks().find((track) => {
          if (track.srcDeviceId && track.srcDeviceId === device.deviceId) {
            return true;
          }
          const { deviceId } = track.getSettings();
          return device.deviceId === deviceId;
        })
      );
    }) || { deviceId: devices[0].deviceId }
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    margin: theme.spacing(0, 0, 2, 0),
    width: '100%',
    overflow: 'auto',
  },
}));

/**
 * Device Picker
 *
 * NOTE: Devices have an empty ("") label if the browser is Firefox, or
 * Chrome does not have access permissions.
 **/
const DevicePicker = ({
  label,
  stream,
  sinkId = null,
  devices = [],
  heading = '',
  hasError = false,
  selected = null,
  disabled = false,
  children,
  onChange,
}) => {
  const getSelectedValue = () => {
    return (
      sinkId || selected || findDeviceUsedInStream(devices, stream).deviceId
    );
  };

  const handleChange = (event) => {
    const device = devices.find((dev) => dev.deviceId === event.target.value);
    if (!device) return;
    onChange(Object.assign({ deviceLabel: device.label }, event));
  };

  const classes = useStyles();

  if (!heading) {
    heading = I18n.t('label:device');
  }

  return (
    <div className={classes.root}>
      <FormControl disabled={disabled || hasError || devices.length === 0}>
        <Select
          value={getSelectedValue()}
          onChange={handleChange}
          color="secondary"
          SelectDisplayProps={{ 'aria-label': label }}
        >
          {devices.map((device, index) => (
            <MenuItem key={index} value={device.deviceId || 'default'}>
              {device.label === 'System default'
                ? I18n.t('label:system_default')
                : device.label || `${heading} ${index + 1}`}
            </MenuItem>
          ))}
          {devices.length === 0 && (
            <MenuItem key={'no-device'} value={'default'}>
              {I18n.t('label:no_device')}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      {children}
    </div>
  );
};

export default DevicePicker;
