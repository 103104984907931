import I18n from 'i18n-js';
import update from 'immutability-helper';
import BaseAction from './BaseActions.js';
import HelpCenter from '../HelpCenter.js';

/**
 * Show file preview dialog.
 **/
class ShowFilePreview extends BaseAction {
  process(state) {
    return {
      file: this.message.file,
      visible: update(state.visible, {
        dialog: { $set: 'presentation_preview' },
      }),
    };
  }
}

/**
 * Show guest link.
 **/
class ShowGuestLink extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'guestlink' },
      }),
    };
  }
}

/**
 * Show drop zone.
 **/
class ShowDropZone extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'drop_zone' },
      }),
    };
  }
}

/**
 * Show live stream dialog.
 **/
class ShowLivestreamDialog extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'live_stream' },
      }),
    };
  }
}

/**
 * Show share dialog.
 **/
class ShowShareDialog extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'share' },
      }),
    };
  }
}

/**
 * Show room share dialog.
 **/
class ShowRoomShareDialog extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'room_share_dialog' },
      }),
    };
  }
}

/**
 * Show recording dialog.
 **/
class ShowRecordingDialog extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'recording' },
      }),
    };
  }
}

/**
 * Show recording dialog.
 **/
class ShowRecordingStopDialog extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'recording_stop' },
      }),
    };
  }
}

/**
 * Show feedback dialog.
 **/
class ShowFeedbackDialog extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'feedback' },
      }),
    };
  }
}

/**
 * Show error dialog.
 **/
class ShowErrorDialog extends BaseAction {
  get error() {
    const { name, message } = this.message.error;
    return {
      url: HelpCenter.fetch(name),
      message: I18n.t(name) || message,
    };
  }

  process(state, props) {
    return {
      error: this.error,
      visible: update(state.visible, {
        dialog: { $set: 'error_dialog' },
      }),
    };
  }
}

/**
 * Show layout dialog.
 **/
class ShowLayoutSelectionDialog extends BaseAction {
  process(state, props) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'layout_selection' },
      }),
    };
  }
}

/**
 * Show layout dialog.
 **/
class ShowLayoutDialog extends BaseAction {
  process(state, props) {
    return {
      visible: update(state.visible, {
        dialog: { $set: this.message.layout },
      }),
    };
  }
}

/**
 * Show Experimental Dialog
 **/
class ShowExperimentalDialog extends BaseAction {
  process(state, props) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'experimental' },
      }),
    };
  }
}

class ShowHelpDialog extends BaseAction {
  process(state, props) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'help' },
      }),
    };
  }
}

class HideDialog extends BaseAction {
  process(state, props) {
    return {
      visible: update(state.visible, {
        dialog: { $set: '' },
      }),
    };
  }
}

class ShowDataSaverDialog extends BaseAction {
  process(state, props) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'data_saver_dialog' },
      }),
    };
  }
}

class ShowLockMeetingDialog extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'lock_meeting_prompt' },
      }),
    };
  }
}

class ShowExitDialog extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'exit_prompt' },
      }),
    };
  }
}

class ShowGuestExitDialog extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'exit_prompt_guest' },
      }),
    };
  }
}

class ShowKickUserDialog extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'kick_user_prompt' },
      }),
      kickUser: update(state.kickUser, {
        user: { $set: this.message.user },
      }),
    };
  }
}

class ShowRevitalizeStreamDialog extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'revitalize_stream_dialog' },
      }),
    };
  }
}

class ShowYoutubePopup extends BaseAction {
  process(state, props, handleEvent) {
    const { eyeson } = props;
    let url = null;
    let win = null;
    if (state.youtubeUserToken) {
      url = `/youtube-popup?token=${state.youtubeUserToken}`;
    } else {
      if (!eyeson.room.guest_token) {
        props.enqueueSnackbar('Missing guest token');
        return null;
      }
      url = `/youtube-popup?guest=${eyeson.room.guest_token}&user=${eyeson.user.name}`;
    }
    if (url) {
      if (state.quality === 'medium' || state.quality === 'low') {
        url += `&quality=${state.quality}`;
      }
      if (
        Reflect.has(state.options.custom_fields, 'locale') &&
        state.options.custom_fields.locale !== 'en'
      ) {
        url += `&locale=${state.options.custom_fields.locale}`;
      }
      if (this.message.url) {
        url += `&url=${this.message.url}`;
      }
      win = window.open(
        url,
        'eyeson-youtube-popup',
        `popup,width=800,height=450,top=${window.screenTop + 20},left=${
          window.screenLeft + 20
        }`
      );
      if (win) {
        if (!state.youtubeUserToken) {
          win.setYoutubeUserToken = (token) => {
            handleEvent({ type: 'set_youtube_user_token', token });
          };
        }
      }
    }
    if (!win) {
      props.enqueueSnackbar('Unable to open popup', {
        variant: 'warning',
        autoHideDuration: 5 * 1000,
      });
      if (typeof this.message.onError === 'function') {
        this.message.onError('Unable to open popup');
      }
    }
    return null;
  }
}

class SetYoutubeUserToken extends BaseAction {
  process() {
    return {
      youtubeUserToken: this.message.token,
    };
  }
}

/**
 * Show share location dialog.
 **/
class ShowShareLocationDialog extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: { $set: 'share_location_dialog' },
      }),
    };
  }
}

export {
  HideDialog,
  ShowDropZone,
  ShowGuestLink,
  ShowHelpDialog,
  ShowShareDialog,
  ShowErrorDialog,
  ShowFilePreview,
  ShowLayoutDialog,
  ShowDataSaverDialog,
  ShowFeedbackDialog,
  ShowRoomShareDialog,
  ShowRecordingDialog,
  ShowRecordingStopDialog,
  ShowLivestreamDialog,
  ShowExperimentalDialog,
  ShowLayoutSelectionDialog,
  ShowLockMeetingDialog,
  ShowExitDialog,
  ShowGuestExitDialog,
  ShowKickUserDialog,
  ShowRevitalizeStreamDialog,
  ShowYoutubePopup,
  SetYoutubeUserToken,
  ShowShareLocationDialog,
};
