import Logger from '../Logger.js';
import BaseEvent from './BaseEvent.js';
import MediaStreamBuilder from '../MediaStreamBuilder.js';
import FeatureDetector from '../FeatureDetector.js';
import {
  toggleAudio,
  toggleVideo,
  toggleCamera,
  toggleVbgTrack,
  isNinjaStreamTrack,
  isScreenAsVideoTrack,
  getScreenAsVideoTracks,
  isCanvasPresentationStream
} from '../utils/StreamHelpers.js';
import MicMixer from '../MicMixer.js';

/**
 * ChangeStreamEvent "changes" a stream with the given options.
 *
 * The MediaStream is only updated if the Browser turns off device activity
 * indicators on disable (i.e. setting the video tracks `.enabled` prop to
 * false). This is the case for Firefox (May 2019).
 *
 * For other Browsers, a totally new MediaStream is acquired. Although some
 * of its tracks might be re-used / kept alive.
 * See `.setStream` in SDH for more details of current implementation.
 **/
class ChangeStreamEvent extends BaseEvent {
  // eslint-disable-next-line max-statements
  handle({ audio = true, video = true, screen = false, surface }) {
    Logger.debug(
      `ChangeStreamEvent::handle audio: ${audio} video: ${video} screen: ${screen}`
    );
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    if (_session.micMixer) {
      _session.micMixer.destroy();
      _session.micMixer = null;
    }
    const changeStream = () => {
      // eslint-disable-next-line max-statements
      return new Promise((resolve, reject) => {
        if (
          FeatureDetector.stopsDeviceActivityIndicatorOnDisable() &&
          !screen
        ) {
          const [videoTrack] = _session.localStream.getVideoTracks();
          const [audioTrack] = _session.localStream.getAudioTracks();
          if (
            !(video && isNinjaStreamTrack(videoTrack)) &&
            videoTrack.readyState !== 'ended' &&
            audioTrack.readyState !== 'ended' &&
            !isScreenAsVideoTrack(videoTrack)
          ) {
            if (_session.vbgMixer && _session.vbgMixer.originalStream) {
              _session.vbgMixer[video ? 'start' : 'stop']();
              toggleVbgTrack(_session.localStream, video);
              toggleCamera(_session.vbgMixer.originalStream, video);
            } else {
              toggleCamera(_session.localStream, video);
            }
            toggleAudio(_session.localStream, audio);
            resolve(_session.localStream);
            return;
          }
        }
        if (_session.vbgMixer) {
          _session.vbgMixer.stopOriginalStream();
          _session.vbgMixer.terminate();
        }
        const options = {
          audio: audio,
          video: video,
          virtualBackground: _session.options.virtualBackground,
          existingStream: _session.localStream,
          vbgMixer: _session.vbgMixer,
          deviceMonitor: _session.deviceMonitor,
          audioPassthrough: _session.options.audioPassthrough
        };
        if (screen) {
          _session.micMixer = new MicMixer();
          options.screen = true;
          options.video = false;
          options.surface = surface;
          options.virtualBackground = false;
          options.vbgMixer = null;
          options.micMixer = _session.micMixer;
        }
        new MediaStreamBuilder(options)
          .onBrokenTrackError(() => {
            _session.emit({ type: 'broken_track_error' });
          })
          .start()
          .then(resolve)
          .catch(reject);
      });
    };

    if (_session.externalStream) {
      toggleAudio(_session.externalStream, audio);
      toggleVideo(_session.externalStream, video);
      _session.send({ type: 'mute_video', on: !video });
      return Promise.resolve();
    }

    return (
      changeStream()
        .then(stream => {
          const [screenTrack] = getScreenAsVideoTracks(stream);
          if (screenTrack) {
            screenTrack.addEventListener('ended', () => {
              _session.emit({ type: 'stop_screen_video' });
            });
          }
          return stream;
        })
        .then(_session.setStream)
        .then(stream => {
          if (isCanvasPresentationStream(stream)) {
            _session.canvasMixer.setStream(stream);
          } else {
            _session.send({ type: 'mute_video', on: !(video || screen) });
          }
        })
        // eslint-disable-next-line max-statements
        .catch(err => {
          Logger.error(err);
          if (
            err instanceof Error &&
            [
              'NotReadableError',
              'DevicesNotFoundError',
              'NotFoundError'
            ].includes(err.name)
          ) {
            _session.emit({ type: 'warning', name: 'error_' + err.name });
          }
          if (screen) {
            if (_session.micMixer) {
              _session.micMixer.destroy();
              _session.micMixer = null;
            }
            let name = `error_Screen_${err.name}`;
            if (err.message.indexOf('not start audio') > -1) {
              name = `error_Screen_${err.name}_Audio`;
            }
            _session.emit({ type: 'screen_capture_error', name });
          }
        })
    );
  }
}

export default ChangeStreamEvent;
