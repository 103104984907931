import I18n from 'i18n-js';
import update from 'immutability-helper';
import { StreamHelpers, FeatureDetector } from 'eyeson';
import BaseAction from './BaseActions.js';
import SpeechHelper from '../SpeechHelper.js';
import supports from '../SupportHelper.js';
import instanceHelper from '../eyesonInstanceHelper.js';
import tabAudioHelper from '../eyesonTabAudioHelper.js';
import micIndicator from '../MicIndicator.js';
import WebHIDHelper from '../WebHIDHelper.js';
import InterCommunicationHelper from '../InterCommunicationHelper.js';

/**
 * Set the stream from an incoming session.
 **/
class AcceptSession extends BaseAction {
  process(state) {
    const { localStream, remoteStream } = this.message;
    let { speech } = state;
    let hasAudio = StreamHelpers.hasAudio(localStream);
    const hasVideo = StreamHelpers.hasVideo(localStream);

    micIndicator.connect(localStream);

    if (!hasAudio) {
      speech = SpeechHelper.startDetection(state, localStream);
    }
    if (supports.PiPConferenceControls) {
      navigator.mediaSession.setMicrophoneActive(hasAudio);
      navigator.mediaSession.setCameraActive(hasVideo);
    }
    WebHIDHelper.setMuteActive(!hasAudio);

    const sessionCount = state.sessionCount + 1;

    InterCommunicationHelper.onMeetingJoin();

    return {
      active: update(state.active, {
        video: { $set: hasVideo },
        audio: { $set: hasAudio },
      }),
      localStream: localStream,
      stream: remoteStream,
      accepted: true,
      connecting: FeatureDetector.hasTrackOnUnmute(),
      speech: speech,
      restartAttempt: 0,
      sessionCount: sessionCount,
    };
  }
}

class TrackUnmuted extends BaseAction {
  process() {
    return { connecting: false };
  }
}

/**
 * Reset the stream in case devices changed and define the ouput devices via
 * the sinkId
 **/
class DeviceUpdate extends BaseAction {
  process(state, props) {
    props.eyeson.send({
      type: 'start_stream',
      audio: state.active.audio,
      video: state.active.video,
      virtualBackground: this.message.virtualBackground !== 'off',
      audioPassthrough: this.message.audioPassthrough,
    });
    return {
      active: update(state.active, { screenAsVideo: { $set: false } }),
      sinkId: this.message.sinkId,
      virtualBackground: this.message.virtualBackground,
      audioPassthrough: this.message.audioPassthrough,
    };
  }
}

class DeviceSupport extends BaseAction {
  process() {
    return {
      videoSupport: this.message.videoSupport,
      audioSupport: this.message.audioSupport,
    };
  }
}

class ToggleVideo extends BaseAction {
  process(state, props) {
    if (!state.localStream || !state.videoSupport) {
      return {};
    }

    const videoEnabled = !state.active.video;

    if (!state.active.screen_capture || props.environment.canMix) {
      const options = {
        type: 'change_stream',
        audio: state.active.audio,
        video: videoEnabled,
      };
      if (state.active.screenAsVideo) {
        options.video = false;
        options.screen = videoEnabled;
        options.surface = 'monitor';
      }
      props.eyeson.send(options);
    }
    if (supports.PiPConferenceControls) {
      navigator.mediaSession.setCameraActive(videoEnabled);
    }

    return {
      isChangingStream: true,
      active: update(state.active, { video: { $set: videoEnabled } }),
    };
  }
}

class ToggleAudio extends BaseAction {
  process(state) {
    if (!state.localStream || !state.audioSupport || state.isChangingStream) {
      return {};
    }

    const audioEnabled = !state.active.audio;
    let speech = null;

    if (audioEnabled) {
      speech = SpeechHelper.stopDetection(state);
    } else {
      speech = SpeechHelper.startDetection(state, state.localStream);
    }

    StreamHelpers.toggleAudio(state.localStream, audioEnabled);
    if (supports.PiPConferenceControls) {
      navigator.mediaSession.setMicrophoneActive(audioEnabled);
    }
    WebHIDHelper.setMuteActive(!audioEnabled);

    return {
      speech: speech,
      active: update(state.active, { audio: { $set: audioEnabled } }),
    };
  }
}

class PushToTalk extends BaseAction {
  process(state) {
    const toggleAudio = new ToggleAudio(this.message);
    const audioEnabled = state.active.audio;
    const pttMessageState = this.message.state;
    const pttAppState = state.active.push_to_talk;
    if (
      (audioEnabled && pttAppState && pttMessageState === 'off') ||
      (!audioEnabled && !pttAppState && pttMessageState === 'on')
    ) {
      const result = toggleAudio.process(state);
      result.active = update(result.active, {
        push_to_talk: { $set: !pttAppState },
      });
      return result;
    }
    return {};
  }
}

/**
 * Toggle camera
 **/
class ToggleCamera extends BaseAction {
  process(state, props) {
    const { localStream } = state;
    if (!localStream || !state.videoSupport) {
      return {};
    }
    const facingMode =
      StreamHelpers.getFacingMode(localStream) || state.facingMode;
    const newMode = facingMode === 'user' ? 'environment' : 'user';

    const speech = SpeechHelper.stopDetection(state);

    props.eyeson.send({
      type: 'toggle_camera',
      stream: localStream,
      facingMode: newMode,
    });

    return {
      speech: speech,
      facingMode: newMode,
    };
  }
}

/**
 * Mute audio and video
 **/
class Mute extends BaseAction {
  process(state, props) {
    props.eyeson.send({
      type: 'change_stream',
      video: false,
      audio: false,
      screen: false,
    });
    let speech = SpeechHelper.startDetection(state, state.localStream);
    if (supports.PiPConferenceControls) {
      navigator.mediaSession.setMicrophoneActive(false);
      navigator.mediaSession.setCameraActive(false);
    }
    WebHIDHelper.setMuteActive(true);

    return {
      active: update(state.active, {
        audio: { $set: false },
        video: { $set: false },
      }),
      localStream: state.localStream,
      visible: update(state.visible, {
        dialog: { $set: '' },
      }),
      isChangingStream: true,
      speech: speech,
    };
  }
}

class ScreenAsVideo extends BaseAction {
  process(state, props) {
    props.eyeson.send({
      type: 'start_stream',
      audio: state.active.audio,
      video: false,
      screen: true,
      surface: 'monitor',
      audioPassthrough: state.audioPassthrough,
    });
    if (supports.PiPConferenceControls) {
      navigator.mediaSession.setMicrophoneActive(state.active.audio);
      navigator.mediaSession.setCameraActive(true);
    }
    WebHIDHelper.setMuteActive(!state.active.audio);
    return {
      wasVideoActive: state.active.video,
      active: update(state.active, {
        audio: { $set: state.active.audio },
        video: { $set: true },
        screenAsVideo: { $set: true },
      }),
    };
  }
}

class ChangeScreenAsVideo extends BaseAction {
  process(_state, props) {
    props.eyeson.send({ type: 'change_screen_video', surface: 'monitor' });
  }
}

class ScreenAsAdditionalUser extends BaseAction {
  process(state) {
    instanceHelper.start(state.options);
  }
}

class ScreenAsAdditionalUserActive extends BaseAction {
  process(state) {
    // also hide dialog!
    return {
      visible: update(state.visible, {
        dialog: { $set: '' },
      }),
      active: update(state.active, {
        screenAddition: { $set: true },
      }),
    };
  }
}

class ScreenAsAdditionalUserStop extends BaseAction {
  process() {
    instanceHelper.stop();
  }
}

class ScreenAsAdditionalUserEnded extends BaseAction {
  process(state) {
    return {
      active: update(state.active, {
        screenAddition: { $set: false },
      }),
    };
  }
}

class StartTabAudio extends BaseAction {
  process() {
    tabAudioHelper.start();
  }
}

class TabAudioActive extends BaseAction {
  process(state) {
    // also hide dialog!
    return {
      visible: update(state.visible, {
        dialog: { $set: '' },
      }),
      active: update(state.active, {
        tabAudio: { $set: true },
      }),
    };
  }
}

class StopTabAudio extends BaseAction {
  process() {
    tabAudioHelper.stop();
  }
}

class TabAudioEnded extends BaseAction {
  process(state) {
    return {
      active: update(state.active, {
        tabAudio: { $set: false },
      }),
    };
  }
}

class ScreenCaptureError extends BaseAction {
  process(state, props) {
    props.enqueueSnackbar(
      I18n.t(this.message.name, `warning: ${this.message.name}`),
      { autoHideDuration: 5 * 1000 }
    );
    if (supports.PiPConferenceControls) {
      navigator.mediaSession.setCameraActive(state.wasVideoActive);
    }
    if (state.active.screenAsVideo) {
      return {
        active: update(state.active, {
          video: { $set: state.wasVideoActive },
          screenAsVideo: { $set: false },
        }),
      };
    }
  }
}

/**
 * Streams were updated
 **/
class StreamUpdate extends BaseAction {
  process(state) {
    const stream = this.message.stream || state.stream;
    const localStream = this.message.localStream || state.localStream;
    const presentationStream =
      this.message.presentationStream || state.presentationStream;
    let speech = state.speech;
    const hasAudio = StreamHelpers.hasAudio(localStream);

    // bit of a special case, when unmute camera -> update stream -> unmute
    // audio
    if (state.active.audio && !hasAudio) {
      StreamHelpers.enableAudio(localStream);
      speech = SpeechHelper.stopDetection(state);
    }
    if (!state.active.audio && !!this.message.localStream) {
      speech = SpeechHelper.startDetection(state, localStream);
    }

    let hasCameraVideo = StreamHelpers.hasVideo(localStream);
    if (StreamHelpers.isCanvasStream(localStream)) {
      hasCameraVideo =
        StreamHelpers.isVBGStream(localStream) ||
        StreamHelpers.hasCameraVideo(localStream);
    }
    if (StreamHelpers.isScreenPresentationStream(localStream)) {
      hasCameraVideo = state.active.video;
    }
    if (supports.PiPConferenceControls) {
      navigator.mediaSession.setMicrophoneActive(hasAudio);
      navigator.mediaSession.setCameraActive(hasCameraVideo);
    }
    WebHIDHelper.setMuteActive(!hasAudio);

    micIndicator.connect(localStream);

    return {
      active: update(state.active, {
        video: { $set: hasCameraVideo },
        audio: { $set: hasAudio },
      }),
      isChangingStream: false,
      stream: stream,
      localStream: localStream,
      presentationStream: presentationStream,
      speech: speech,
    };
  }
}

class HandleStfu extends BaseAction {
  process(state) {
    if (
      !state.localStream ||
      !state.audioSupport ||
      state.active.isPresenter ||
      this.message.user.id === state.user.id
    ) {
      return {};
    }

    StreamHelpers.disableAudio(state.localStream);
    let speech = SpeechHelper.startDetection(state, state.localStream);
    if (supports.PiPConferenceControls) {
      navigator.mediaSession.setMicrophoneActive(false);
    }
    WebHIDHelper.setMuteActive(true);
    return {
      active: update(state.active, {
        audio: { $set: false },
        push_to_talk: { $set: false },
      }),
      remoteMute: update(state.remoteMute, {
        user: { $set: this.message.user },
      }),
      visible: update(state.visible, {
        dialog: { $set: 'remote_mute' },
      }),
      speech: speech,
    };
  }
}

class Stfu extends BaseAction {
  process(_state, props) {
    props.eyeson.send({ type: 'request_stfu' });
  }
}

/*
 * This is only triggered on ios devices.
 * simply restart gUM
 */
class HandleBrokenTrackError extends BaseAction {
  process(state, props) {
    props.eyeson.send({
      type: 'change_stream',
      audio: state.active.audio,
      video: state.active.video,
    });
  }
}

class RevitalizeRemoteStream extends BaseAction {
  process() {
    const revitalize = (element) => {
      const stream = element.srcObject;
      if (stream) {
        element.srcObject = null;
        element.srcObject = stream;
      }
    };
    document.querySelectorAll('video').forEach(revitalize);
    document.querySelectorAll('audio').forEach(revitalize);
  }
}

class FixSafariSelfAudioBug extends BaseAction {
  process() {
    if (
      FeatureDetector.isSafari() &&
      !FeatureDetector.isIOSDevice() &&
      FeatureDetector.browserVersion() === 15
    ) {
      const revitalize = (video) => {
        if (video.srcObject !== null && video.muted) {
          video.muted = false;
          video.muted = true;
        }
      };
      document.querySelectorAll('video.local-stream').forEach(revitalize);
      document.querySelectorAll('video.self-view-video').forEach(revitalize);
    }
  }
}

class AudioDeviceWarning extends BaseAction {
  process(state, _props) {
    const { newAudioDevice = null } = this.message;
    return {
      visible: update(state.visible, {
        dialog: { $set: 'audio_device_lost_dialog' },
      }),
      newAudioDevice: newAudioDevice,
    };
  }
}

class VideoDeviceWarning extends BaseAction {
  process(state, _props) {
    // props.enqueueSnackbar('Video device stopped working.', { variant: 'warning', autoHideDuration: 5 * 1000 });
    return {
      visible: update(state.visible, {
        dialog: { $set: 'video_device_lost_dialog' },
      }),
    };
  }
}

class AllDevicesWarning extends BaseAction {
  process(state, _props) {
    const { newAudioDevice = null } = this.message;
    return {
      visible: update(state.visible, {
        dialog: { $set: 'all_devices_lost_dialog' },
      }),
      newAudioDevice: newAudioDevice,
    };
  }
}

export {
  Stfu,
  Mute,
  HandleStfu,
  PushToTalk,
  ToggleAudio,
  ToggleVideo,
  StreamUpdate,
  DeviceUpdate,
  ToggleCamera,
  TrackUnmuted,
  AcceptSession,
  DeviceSupport,
  ScreenAsVideo,
  ChangeScreenAsVideo,
  ScreenAsAdditionalUser,
  ScreenAsAdditionalUserActive,
  ScreenAsAdditionalUserStop,
  ScreenAsAdditionalUserEnded,
  ScreenCaptureError,
  HandleBrokenTrackError,
  RevitalizeRemoteStream,
  FixSafariSelfAudioBug,
  AudioDeviceWarning,
  VideoDeviceWarning,
  AllDevicesWarning,
  StartTabAudio,
  StopTabAudio,
  TabAudioActive,
  TabAudioEnded,
};
