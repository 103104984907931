import { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import I18n from 'i18n-js';
import {
  DialogActions,
  DialogContentText,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Button from '../generic/Button.js';
import DialogWindow from '../dialog/DialogWindow.js';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import SettingsIcon from '@material-ui/icons/Settings';
import WebHIDHelper from '../../utils/WebHIDHelper.js';
import StreamdeckHelper from '../../utils/StreamdeckHelper.js';
import { ReactComponent as ElgatoLogo } from '../../assets/streamdeck/elgato.svg';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    display: 'inline-block',
    width: '1em',
    verticalAlign: 'middle',
  },
  primaryText: {
    color: () => theme.palette.text.primary,
  },
  secondaryText: {
    color: () => theme.palette.text.secondary,
  },
  linkSecondary: {
    color: () => theme.palette.secondary.main,
  },
}));

const useLabelStyles = makeStyles({
  root: {
    textAlign: 'left',
    color: 'inherit',
  },
});

const CallControlOptions = (props) => {
  const { streamdeckState, onStreamdeckDisable, hidDevices, hidBlocked } =
    props;

  const webhidSupported = WebHIDHelper.isSupported();
  const streamdeckSupported = StreamdeckHelper.isSupported();

  const [webhidDialogOpen, setWebhidDialogOpen] = useState(false);
  const [streamdeckDialogOpen, setStreamdeckDialogOpen] = useState(false);
  const [settingButtonEl, setSettingButtonEl] = useState(null);

  const classes = useStyles();
  const labelStyles = useLabelStyles();

  if (!(webhidSupported || streamdeckSupported)) {
    return '';
  }

  const onSettingButtonClick = (event) => {
    if (webhidSupported && !streamdeckSupported) {
      onOpenWebhidDialog();
    } else if (!webhidSupported && streamdeckSupported) {
      onOpenStreamDeckDialog();
    } else {
      setSettingButtonEl(event.currentTarget);
    }
  };

  const closeMenu = () => {
    setSettingButtonEl(null);
  };

  const onOpenWebhidDialog = () => {
    closeMenu();
    WebHIDHelper.emitDeviceList();
    setWebhidDialogOpen(true);
  };

  const closeWebhidDialog = () => {
    setWebhidDialogOpen(false);
  };

  const onOpenStreamDeckDialog = () => {
    closeMenu();
    setStreamdeckDialogOpen(true);
  };

  const closeStreamDeckDialog = () => {
    setStreamdeckDialogOpen(false);
  };

  const enableStreamdeck = () => {
    StreamdeckHelper.enableSetting();
    window.location.reload();
  };

  const disableStreamdeck = () => {
    StreamdeckHelper.disableSetting();
    StreamdeckHelper.destroy();
    onStreamdeckDisable();
    closeStreamDeckDialog();
  };

  const createLinkAsString = (text, url) => {
    return ReactDOMServer.renderToString(
      <Link
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.linkSecondary}
      >
        {text}
      </Link>
    );
  };

  return (
    <>
      <section className={classes.section}>
        <Typography variant="body2" variantMapping={{ body2: 'div' }}>
          <InputLabel classes={labelStyles}>
            {I18n.t('label:call_control_options:title')}
          </InputLabel>
        </Typography>
        <Tooltip
          title={I18n.t('label:call_control_options:open_settings')}
          placement="top"
        >
          <span>
            <IconButton
              onClick={onSettingButtonClick}
              aria-controls="callcontrol-menu"
              aria-haspopup="true"
            >
              <SettingsIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Menu
          id="callcontrol-menu"
          keepMounted
          anchorEl={settingButtonEl}
          open={Boolean(settingButtonEl)}
          onClose={closeMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        >
          <MenuItem onClick={onOpenWebhidDialog}>
            {I18n.t('dialog:webhid:title')}
          </MenuItem>
          <MenuItem onClick={onOpenStreamDeckDialog}>
            {I18n.t('dialog:stream_deck:title')}
          </MenuItem>
        </Menu>
      </section>
      {/* --- WebHID Dialog --- */}
      <DialogWindow
        id="hid-device-dialog"
        title={I18n.t('dialog:webhid:title')}
        maxWidth="sm"
        fullWidth
        open={webhidDialogOpen}
        onClose={closeWebhidDialog}
      >
        <div id="hid-device-dialog-desc" tabIndex={0}>
          <DialogContentText className={classes.secondaryText}>
            {I18n.t('dialog:webhid:description')}
          </DialogContentText>
          {hidBlocked ? (
            <DialogContentText color="textPrimary">
              {I18n.t('error:webhid:prevent_multiple_instances')}
            </DialogContentText>
          ) : (
            <List>
              {hidDevices.length === 0 ? (
                <ListItem key="none">
                  <ListItemText>
                    {I18n.t('label:webhid:preview_no_device')}
                  </ListItemText>
                </ListItem>
              ) : (
                hidDevices.map((device) => (
                  <ListItem key={device.id}>
                    <ListItemText>{device.productName}</ListItemText>
                    <ListItemSecondaryAction>
                      <Tooltip
                        title={I18n.t('label:webhid:remove_device')}
                        placement="top"
                      >
                        <IconButton
                          edge="end"
                          aria-label={I18n.t('label:webhid:remove_device')}
                          onClick={() => WebHIDHelper.removeDevice(device.id)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              )}
            </List>
          )}
        </div>
        <DialogActions>
          <Button type="secondary" onClick={closeWebhidDialog}>
            {I18n.t('label:close')}
          </Button>
          <Button
            disabled={hidBlocked}
            onClick={() => WebHIDHelper.pairDeviceRequest()}
            aria-label={I18n.t('label:webhid:pair_device')}
          >
            {I18n.t('label:webhid:pair_device')}
          </Button>
        </DialogActions>
      </DialogWindow>
      {/* --- Stream Deck Dialog --- */}
      <DialogWindow
        id="streamdeck-dialog"
        title={
          <>
            <span className={classes.logo}>
              <ElgatoLogo />
            </span>{' '}
            {I18n.t('dialog:stream_deck:title')}
          </>
        }
        maxWidth="sm"
        fullWidth
        open={streamdeckDialogOpen}
        onClose={closeStreamDeckDialog}
      >
        <div id="streamdeck-dialog-desc" tabIndex={0}>
          {streamdeckState === 'connected' ? (
            <>
              <DialogContentText color="textPrimary">
                {I18n.t('status:stream_deck:connected')}
              </DialogContentText>
              <DialogContentText>
                {I18n.t('action:stream_deck:deactivate')}
              </DialogContentText>
            </>
          ) : streamdeckState === 'not_available' ? (
            <>
              <DialogContentText color="textPrimary">
                {I18n.t('status:stream_deck:not_available')}
              </DialogContentText>
              <DialogContentText>
                {I18n.t('action:stream_deck:deactivate')}
              </DialogContentText>
            </>
          ) : (
            <>
              <DialogContentText
                className={classes.primaryText}
                dangerouslySetInnerHTML={{
                  __html: I18n.t('status:stream_deck:disconnected', {
                    plugin: createLinkAsString(
                      I18n.t('stream_deck:plugin:title'),
                      'https://explore.eyeson.com/stream-deck-plugin'
                    ),
                  }),
                }}
              />
              <DialogContentText className={classes.secondaryText}>
                {I18n.t('action:stream_deck:activate')}
              </DialogContentText>
            </>
          )}
        </div>
        <DialogActions>
          <Button type="secondary" onClick={closeStreamDeckDialog}>
            {I18n.t('label:close')}
          </Button>
          {streamdeckState === 'off' ? (
            <Button
              onClick={enableStreamdeck}
              aria-label={I18n.t('label:activate')}
            >
              {I18n.t('label:activate')}
            </Button>
          ) : (
            <Button
              onClick={disableStreamdeck}
              aria-label={I18n.t('label:deactivate')}
            >
              {I18n.t('label:deactivate')}
            </Button>
          )}
        </DialogActions>
      </DialogWindow>
    </>
  );
};

export default CallControlOptions;
