import { FeatureDetector } from 'eyeson';

const isSupported = FeatureDetector.canGeolocation();

const _mapLookup = new Map();
const _markerUrl = {
  marker:
    'https://cdn.jsdelivr.net/npm/leaflet@1.9.4/dist/images/marker-icon.png',
  shadow:
    'https://cdn.jsdelivr.net/npm/leaflet@1.9.4/dist/images/marker-shadow.png',
};

const loadStyles = () => {
  return new Promise((resolve) => {
    if (
      document.querySelector(
        'link[href="https://cdn.jsdelivr.net/npm/leaflet@1.9.4/dist/leaflet.min.css"]'
      )
    ) {
      resolve();
      return;
    }
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href =
      'https://cdn.jsdelivr.net/npm/leaflet@1.9.4/dist/leaflet.min.css';
    document.head.appendChild(link);
    link.onload = () => resolve();
    link.onerror = () => resolve();
  });
};

const addMap = async (mapId, latitude, longitude, zoom) => {
  await loadStyles();
  const Leaflet = await import(
    /* webpackIgnore: true */
    'https://cdn.jsdelivr.net/npm/leaflet@1.9.4/+esm'
  );
  if (_mapLookup.has(mapId)) {
    const oldMap = _mapLookup.get(mapId);
    oldMap.remove();
  }
  const map = Leaflet.map(mapId).setView([latitude, longitude], zoom);
  Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(map);
  Leaflet.marker([latitude, longitude]).addTo(map);
  _mapLookup.set(mapId, map);
};

const addMapWithPositioning = async (
  mapId,
  latitude,
  longitude,
  zoom,
  onPositionUpdate
) => {
  await loadStyles();
  const Leaflet = await import(
    /* webpackIgnore: true */
    'https://cdn.jsdelivr.net/npm/leaflet@1.9.4/+esm'
  );
  if (_mapLookup.has(mapId)) {
    const oldMap = _mapLookup.get(mapId);
    oldMap.remove();
    _mapLookup.delete(mapId);
  }
  if (!document.getElementById(mapId)) {
    return;
  }
  const map = Leaflet.map(mapId).setView([latitude, longitude], zoom);
  Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(map);
  map.on('moveend', () => {
    const position = map.getCenter();
    onPositionUpdate({ latitude: position.lat, longitude: position.lng });
  });
  _mapLookup.set(mapId, map);
};

const setMapPosition = (mapId, latitude, longitude, zoom) => {
  const map = _mapLookup.get(mapId);
  if (map) {
    map.setView([latitude, longitude], zoom);
  }
};

const removeMap = (mapId) => {
  const map = _mapLookup.get(mapId);
  if (map) {
    map.remove();
    _mapLookup.delete(mapId);
  }
};

const instance = {
  isSupported,
  addMap,
  addMapWithPositioning,
  setMapPosition,
  removeMap,
  markerUrl: _markerUrl,
};

export default instance;
